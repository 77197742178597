<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" v-if="!loading && data.completed">
                <Alert
                    type="success"
                    message="This sign-up request has been completed."
                />
            </v-col>
            <v-col cols="12" v-if="!loading && data.deleted">
                <Alert
                    type="error"
                    message="This signup request has been rejected."
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <SignupCard
                    :data="data"
                    :loading="loading"
                    @reject="dialog.reject = true"
                    @unapprove="dialog.unapprove = true"
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <NewPartnerProcessCard :data="data" :loading="loading" />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                lg="4"
                v-if="!data.completed && !data.deleted && !data.approved"
            >
                <SignupApprovalCard
                    :data="data"
                    :loading="loading"
                    @approve="dialog.approvePartner = true"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                lg="4"
                v-if="data.completed || data.deleted"
            >
                <SignupClosedCard :data="data" :loading="loading" />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                lg="4"
                v-if="
                    !data.completed &&
                    !data.deleted &&
                    !data.agreement_reviewed &&
                    data.approved
                "
            >
                <SignupAgreementCard
                    :data="data"
                    :loading="loading"
                    @send="dialog.send = true"
                    @sent="dialog.sent = true"
                    @upload="dialog.upload = true"
                    @review="dialog.review = true"
                    @unsign="dialog.unsign = true"
                />
            </v-col>
            <v-col
                cols="12"
                sm="6"
                lg="4"
                v-if="
                    !data.completed && !data.deleted && data.agreement_reviewed
                "
            >
                <SignupCompletionCard
                    :data="data"
                    :loading="loading"
                    @complete="dialog.complete = true"
                />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <SignupBusinessDetailsCard :data="data" :loading="loading" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <SignupContactCard :data="data" @edit="dialog.contact = true" />
            </v-col>
            <v-col cols="12" sm="6" lg="4">
                <AddressCard
                    :data="data"
                    :loading="loading"
                    :hide-actions="data.agreement_reviewed || !!data.deleted"
                    @edit="dialog.address = true"
                />
            </v-col>
            <v-col cols="12" lg="6">
                <SignupFilesCard
                    :loading="loading"
                    :data="data"
                    @upload="dialog.upload.visible = true"
                    @save="update"
                />
            </v-col>
            <v-col cols="12" lg="6">
                <HistoryCard
                    title="Sign-up Request History"
                    :filter="historyFilter"
                    @update="updateHistoryFilter"
                    :loading="loading"
                    :signup-id="data.signup_id"
                    relative-date
                />
            </v-col>
        </v-row>
        <ApprovePartnerSignup
            v-if="data.signup_id"
            v-model="dialog.approvePartner"
            @save="update"
            :data="data"
        />
        <UpdateBusinessSignupInfo
            v-if="data.signup_id"
            v-model="dialog.updateBusiness"
            @save="update"
            :data="data"
        />
        <RejectPartnerSignup
            v-if="data.signup_id"
            v-model="dialog.reject"
            @save="update"
            :data="data"
        />
        <UnapproveSignup
            v-if="data.signup_id"
            v-model="dialog.unapprove"
            @save="update"
            :data="data"
        />
        <SendPartnerAgreement
            v-if="data.signup_id"
            v-model="dialog.send"
            @save="update"
            :data="data"
        />
        <SentPartnerAgreement
            v-if="data.signup_id"
            v-model="dialog.sent"
            @save="update"
            :data="data"
        />
        <UploadPartnerAgreement
            v-if="data.signup_id"
            v-model="dialog.upload"
            @save="update"
            :data="data"
        />
        <EditContactSignup
            v-if="data.signup_id"
            v-model="dialog.contact"
            @save="update"
            :data="data"
        />
        <ReviewPartnerAgreement
            v-if="data.signup_id"
            v-model="dialog.review"
            @save="update"
            :data="data"
        />
        <UnsignPartnerSignup
            v-if="data.signup_id"
            v-model="dialog.unsign"
            @save="update"
            :data="data"
        />
        <CompleteSignup
            v-if="data.signup_id"
            v-model="dialog.complete"
            @save="update"
            :data="data"
        />
        <UpdateSignupAddress
            v-if="data.signup_id"
            v-model="dialog.address"
            @save="update"
            :data="data"
        />
    </v-container>
</template>

<script>
import Alert from '@/components/pieces/Alert';
import AddressCard from '@/components/cards/AddressCard';
import SignupContactCard from '@/components/cards/Signup/SignupContactCard';
import SignupCard from '@/components/cards/Signup/SignupCard';
import UpdateBusinessSignupInfo from '@/components/dialogs/Signup/UpdateBusinessSignupInfo';
import NewPartnerProcessCard from '@/components/cards/Signup/NewPartnerProcessCard';
import RejectPartnerSignup from '@/components/dialogs/Signup/RejectPartnerSignup';
import UnapproveSignup from '@/components/dialogs/Signup/UnapproveSignup';
import SendPartnerAgreement from '@/components/dialogs/Signup/SendPartnerAgreement';
import SentPartnerAgreement from '@/components/dialogs/Signup/SentPartnerAgreement';
import UploadPartnerAgreement from '@/components/dialogs/Signup/UploadPartnerAgreement';
import SignupFilesCard from '@/components/cards/Signup/SignupFilesCard';
import ReviewPartnerAgreement from '@/components/dialogs/Signup/ReviewPartnerAgreement';
import CompleteSignup from '@/components/dialogs/Signup/CompleteSignup';
import HistoryCard from '../../../components/cards/shared/HistoryCard';
import SignupApprovalCard from '../../../components/cards/Signup/SignupApprovalCard';
import SignupBusinessDetailsCard from '../../../components/cards/Signup/SignupBusinessDetailsCard';
import ApprovePartnerSignup from '../../../components/dialogs/Signup/ApprovePartnerSignup';
import SignupAgreementCard from '../../../components/cards/Signup/SignupAgreementCard';
import SignupCompletionCard from '../../../components/cards/Signup/SignupCompletionCard';
import SignupClosedCard from '../../../components/cards/Signup/SignupClosedCard';
import UnsignPartnerSignup from '../../../components/dialogs/Signup/UnsignPartnerSignup';
import EditContactSignup from '../../../components/dialogs/Signup/EditContactSignup';
import UpdateSignupAddress from '../../../components/dialogs/Signup/UpdateSignupAddress';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'SignupDetail',
    mixins: [apiMixin],
    data: () => ({
        loading: false,
        data: [],
        historyFilter: {
            page: 1,
            limit: 10
        },
        dialog: {
            approvePartner: false,
            updateBusiness: false,
            reject: false,
            unapprove: false,
            send: false,
            sent: false,
            upload: false,
            unsign: false,
            review: false,
            contact: false,
            address: false,
            complete: false,
        },
    }),
    components: {
        UpdateSignupAddress,
        EditContactSignup,
        UnsignPartnerSignup,
        SignupClosedCard,
        SignupCompletionCard,
        SignupAgreementCard,
        ApprovePartnerSignup,
        SignupBusinessDetailsCard,
        SignupApprovalCard,
        HistoryCard,
        CompleteSignup,
        ReviewPartnerAgreement,
        SignupFilesCard,
        UploadPartnerAgreement,
        SentPartnerAgreement,
        SendPartnerAgreement,
        UnapproveSignup,
        RejectPartnerSignup,
        NewPartnerProcessCard,
        UpdateBusinessSignupInfo,
        SignupCard,
        SignupContactCard,
        AddressCard,
        Alert,
    },
    methods: {
        async get(id) {
            this.loading = true;
            const response = await this.Api.send('post','signup/' + id + '/info');
            this.loading = false;
            if (response.success) {
                this.updateData(response.data);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
        },
        update() {
            this.get(this.$route.params.id);
        },
        updateData(data) {
            data.companyname + ' (' + data.signup_id + ')';
            this.data = data;
        },
        updateHistoryFilter(data) {
            this.historyFilter = { ...this.historyFilter, ...data };
        },
        updateFailed() {
            this.data = [];
        },
    },
    created() {
        this.update();
    },
    beforeRouteUpdate(to, from, next) {
        this.get(to.params.id);
        next();
    },
};
</script>
