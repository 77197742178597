<template>
    <BasicCard
        :loading="loading"
        class="background_accent"
    >
        <template v-slot:feature>
            <v-chip class="primary ml-2">Partner</v-chip>
            <v-icon right x-large>mdi-account-circle</v-icon>
        </template>

        <p class="title text--primary mt-1" v-if="data.name">{{ data.name }}</p>

        <EditPartnerDetails
            key="editPartnerDialog"
            :visible="showDialog"
            v-on:close="closeModal"
            v-on:cancel="showDialog = false"
            :data="data"
        />

        <v-row dense>
            <v-col cols="5">
                <span class="font-weight-bold">Company Name</span>
            </v-col>
            <v-col cols="7">
                <IconWithTooltip
                    v-if="!data.companyname"
                    tooltip="No company name"
                    small
                />
                {{ data.companyname }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">ABN / ACN</span>
            </v-col>
            <v-col cols="7">
                <IconWithTooltip v-if="!data.tax_id" tooltip="No ABN" small />
                {{ data.tax_id }}
            </v-col>
            <v-col cols="5">
                <span class="font-weight-bold">Dealer Code</span>
            </v-col>
            <v-col cols="7">
                <IconWithTooltip
                    v-if="!data.refnum"
                    tooltip="No Dealer Code"
                    small
                />
                {{ data.refnum }}
            </v-col>
        </v-row>
        <Alert
            style="margin-top: 10px"
            type="warning"
            message="Partner details incomplete!"
            v-if="!data.companyname || !data.tax_id || !data.refnum"
        />
        <template v-slot:actions>
            <v-tooltip v-if="$store.state.user.isAdmin" bottom>
                <template #activator="{ on }">
                    <v-btn text v-on="on" v-on:click="showDialog = true">
                        <v-icon left>mdi-pencil</v-icon> Edit
                    </v-btn>
                </template>
                <span>Change Partner Details</span>
            </v-tooltip>
        </template>
    </BasicCard>
</template>

<script>
import Alert from '../../pieces/Alert';
import EditPartnerDetails from '../../dialogs/Partners/EditPartnerDetails';
import IconWithTooltip from '../../pieces/IconWithTooltip';
import BasicCard from '../templates/BasicCard';

export default {
    name: 'PartnerCard',
    components: { BasicCard, IconWithTooltip, EditPartnerDetails, Alert },
    props: ['data', 'loading'],
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        closeModal() {
          this.showDialog = false;
          //this.$emit('update');
        },
    },
};
</script>
