<template>
    <NavMenu
        :hide="hide"
        v-if="$store.state.user.isLoggedIn"
        v-model="show"
        show-shrink-option
    >
        <v-list v-if="hide" class="py-0">
            <v-list-item @click="mainMenu">
                <v-list-item-action>
                    <v-icon v-html="'mdi-chevron-left'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Main Menu</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider />
        </v-list>
        <v-list class="py-0">
            <v-list-item class="py-2">
                <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-bold mb-1"
                        >Commission Admin</v-list-item-title
                    >
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item link :to="{ name: 'CommissionAdminSummary' }">
                <v-list-item-action>
                    <v-icon v-html="'mdi-information'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Summary</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'CommissionDownloadReport' }">
                <v-list-item-action>
                    <v-icon v-html="'mdi-download'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Payment Report</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item disabled>
                <v-list-item-action>
                    <v-icon v-html="'mdi-upload'" />
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Upload Transactions</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <template v-slot:append v-if="hide">
            <v-list class="py-0">
                <v-list-item @click="mainMenu">
                    <v-list-item-action>
                        <v-icon v-html="'mdi-chevron-left'" />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Main Menu</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider />
            </v-list>
        </template>
    </NavMenu>
</template>

<script>
import NavMenu from '../templates/NavMenu';
import { mdiSale } from '@mdi/js';
export default {
    name: 'CommissionMenu',
    components: { NavMenu },
    data: () => ({
        show: false,
        mdiSale: mdiSale,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        partnerData() {
            return null;
        },
    },
    methods: {
        mainMenu: function () {
            this.$emit('menu');
        },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    mounted() {
        this.show = this.value;
    },
};
</script>
