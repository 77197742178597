<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="Name"
                        :disabled="isLoading"
                        v-model="localFilter.name"
                        @keydown.enter="updateLocalFilter"
                    />
                </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    hide-details
                    outlined
                    dense
                    label="Account Type"
                    v-model="localFilter.accountType"
                    :disabled="isLoading"
                    :items="accountTypeOptions"
                    item-text="option"
                    item-value="value"
                />
              </v-col>
                <v-col cols="12" sm="2">
                    <v-select
                        hide-details
                        outlined
                        dense
                        label="Status"
                        v-model="localFilter.status"
                        :disabled="isLoading"
                        :items="statusOptions"
                        item-text="option"
                        item-value="value"
                    />
                </v-col>
              <v-col cols="12" sm="2">
                <v-select
                    hide-details
                    outlined
                    dense
                    label="Customer Type"
                    v-model="localFilter.customerType"
                    :disabled="isLoading"
                    :items="customerTypeOptions"
                    item-text="option"
                    item-value="value"
                />
              </v-col>
                <v-col cols="12" sm="2">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
            </v-row>
        </v-card>
        <PaginationCard
            :page="filter.page ? filter.page : 1"
            :data="data"
            :limit="filter.limit ? filter.limit : 10"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Account type</th>
                        <th>Company</th>
                        <th>Balance</th>
                        <th v-if="getRepNames">Partner</th>
                        <th v-if="$vuetify.breakpoint.smAndUp">Contact</th>
                        <th v-if="$vuetify.breakpoint.smAndUp">Address</th>
                        <th class="text-center">Status</th>
                        <th v-if="$store.state.user.isAdmin" class="text-center">Customer Type</th>
                    </tr>
                </thead>
                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td :colspan="7" class="text-center">
                            No customers found
                        </td>
                    </tr>
                    <tr
                        v-for="item in data.data"
                        :key="item.i_customer"
                        v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'red lighten-4':
                                !$vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'grey darken-3':
                                $vuetify.theme.dark && item.bill_status === 'C',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.bill_status === 'C',
                        }"
                    >
                        <td><router-link
                            :to="{
                                name: 'CustomerSummary',
                                params: { customerId: item.i_customer },
                            }"
                            style="cursor: pointer"
                        >
                            <a class="font-weight-medium">{{ item.name }}</a>
                        </router-link></td>
                        <td>{{ item.customer_class_label}}</td>
                        <td>{{ item.companyname }}</td>
                        <td>
                          <Currency
                              :value="
                    item.i_balance_control_type == '1'
                        ? item.balance
                        : -item.balance
                "
                          />
                        </td>
                        <td v-if="getRepNames">
                            <router-link v-if="item.i_rep" :to="{name: 'AdminPartnerSummary', params: {partnerId: item.i_rep}}">{{
                                item.representative_name
                            }}</router-link>
                        </td>
                        <td v-if="$vuetify.breakpoint.smAndUp">
                            <span v-if="item.salutation"
                                >{{ item.salutation }}
                            </span>
                            <span v-if="item.firstname"
                                >{{ item.firstname }}
                            </span>
                            <span v-if="item.lastname">{{
                                item.lastname
                            }}</span>
                            <span
                                v-if="
                                    !item.salutation &&
                                    !item.firstname &&
                                    !item.lastname
                                "
                                >-</span
                            >
                        </td>
                        <td v-if="!small && item.baddr1">
                            <span v-if="item.baddr1">{{ item.baddr1 }}, </span>
                            <span v-if="item.address_line_2"
                                >{{ item.address_line_2 }},
                            </span>
                            <span v-if="item.city">{{ item.city }} </span>
                            <AddressState :value="item.state" />
                            <span v-if="item.zip"> {{ item.zip }}</span>
                        </td>
                        <td v-if="!small && !item.baddr1">None</td>
                        <td class="text-center">
                            <CustomerStatus :data="item" />
                        </td>
                      <td class="text-center" v-if="$store.state.user.isAdmin">
                        <v-chip
                            small
                            :color="'grey'"
                            :text-color="'white'"
                        >
                          <span v-if="item.i_parent != null">Wholesale Customer</span>
                          <span v-else-if="item.i_rep != null">Standard</span>
                          <span v-else-if="item.i_customer_type == 2">Wholesale Parent</span>
                          <span v-else-if="item.i_customer_class == byoICustomerClass">BYO Wholesale</span>
                          <span v-else>Unknown</span>
                        </v-chip>
                      </td>

                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCard>
    </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import AddressState from '../../pieces/Address/AddressState';
import CustomerStatus from '../../pieces/Customer/CustomerStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import Currency from "../../pieces/Currency";
import partnerDataMixin from "../../../mixins/partnerDataMixin";
export default {
    name: 'CustomerListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        localFilter: {
            name: null,
            status: null,
            accountType: null,
            customerType:null,
        },
        statusOptions: [
            { option: 'Active (all)', value: null },
            { option: 'Active (not suspended)', value: 'O' },
            { option: 'Active (suspended)', value: 'S' },
            { option: 'Terminated', value: 'C' },
        ],
        accountTypeOptions: [
            { option: 'Any', value:null},
            { option: 'SIP Trunk (admin)', value: 'sip-trunk' },
            { option: 'Hosted Extension', value: 'hosted-extension' },
            { option: 'BYO Wholesale', value: 'wholesale-byo' },
        ],
        customerTypeOptions: [
          { option: 'Any', value:null}, //all customers
          { option: 'Admin and Partner Owned', value: 'admin-parent-customer' }, // i_parent = null. So only rep or admin customers
          { option: 'Admin Owned and Wholesale Manged Customers', value: 'admin-wholesale-managed-customer' }, //i_rep = null. So only customers belonging to resellers or admin customers
          { option: 'Managed Wholesale (resellers)', value: 'wholesale-managed-parent' }, //i_customer_type = 2 so only resellers
          { option: 'Admin owned', value: 'admin' }, //i_rep and i_parent both null
        ],
    }),
    components: {
        CustomerStatus,
        AddressState,
        PaginationCard,
        Currency,
    },
    props: {
        hidePagination: {
            type: Boolean,
            default: false,
        },
        repId: {
            type: Number,
            default: null,
        },
        customerId: {
          type: Number,
          default: null,
        },
        getRepNames: {
            type: Boolean,
            default: false,
        },
        small: {
            type: String,
            default: null,
        },
    },
    methods: {
        async update() {
            this.isLoading = true;
            this.getLocalFilters();

            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status && this.filter.status !== '')
                params.status = this.filter.status;
            if (this.filter.accountType && this.filter.accountType !== '')
              params.accountType = this.filter.accountType;
            if (this.filter.customerType && this.filter.customerType !== '')
              params.customerType = this.filter.customerType;
            if (this.filter.name && this.filter.name !== '')
                params.name = this.filter.name;
            if (this.repId > 0) {
                params.i_rep = this.repId;
            } else {
                if (this.getRepNames) params.get_rep_names = 1;
            }

            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            const response = await this.Api.send('get','customers?'+queryString);
            this.isLoading = false;
            if (response.success) {
                this.data = response.data;
                this.firstLoad = false;
            } else {
                this.data = [];
            }
        },
    },

  computed:{
    byoICustomerClass() {
      return process.env.VUE_APP_BYO_WHOLESALE_I_CUSTOMER_CLASS;
    }
  }
};
</script>