<template>
    <BasicCard title="Sign-up Request" :loading="loading">
        <p class="title text--primary mt-1">
            <span v-if="data.completed">Request Completed</span>
            <span v-if="data.deleted">Request Rejected</span>
        </p>
        <p class="text--primary" v-if="data.completed">
            This request has been completed.
        </p>
        <p class="text--primary" v-if="data.i_rep">
            A representative has already been generated.
        </p>
        <p class="text--primary" v-if="data.deleted">
            This request has been rejected.
        </p>
        <template v-slot:actions>
            <ButtonWithTooltip
                v-if="data.i_rep"
                text="View Partner"
                tooltip="View partner account"
                icon="mdi-eye"
                color="primary"
                @click="$router.push('/partners/' + data.i_rep)"
                flat
            />
        </template>
    </BasicCard>
</template>

<script>
import BasicCard from '@/components/cards/templates/BasicCard';
import ButtonWithTooltip from '../../pieces/ButtonWithTooltip';
export default {
    name: 'SignupClosedCard',
    components: { ButtonWithTooltip, BasicCard },
    props: ['data', 'loading'],
};
</script>
