<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card :loading="loading" :disabled="loading">
                    <v-row dense>
                        <v-col cols="12">
                            <v-toolbar flat>
                                <v-btn
                                    outlined
                                    class="mr-4"
                                    color="grey darken-2"
                                    @click="setToday"
                                >
                                    Today
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="prev"
                                >
                                    <v-icon small> mdi-chevron-left </v-icon>
                                </v-btn>
                                <v-btn
                                    fab
                                    text
                                    small
                                    color="grey darken-2"
                                    @click="next"
                                    class="mr-2"
                                >
                                    <v-icon small> mdi-chevron-right </v-icon>
                                </v-btn>
                                <v-toolbar-title v-if="$refs.calendar">
                                    {{ $refs.calendar.title }}
                                </v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-menu bottom right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            outlined
                                            color="grey darken-2"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <span>{{ filter.type }}</span>
                                            <v-icon right>
                                                mdi-menu-down
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item
                                            @click="updateCalendarType('day')"
                                        >
                                            <v-list-item-title
                                                >Day</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-list-item
                                            @click="updateCalendarType('week')"
                                        >
                                            <v-list-item-title
                                                >Week</v-list-item-title
                                            >
                                        </v-list-item>
                                        <v-list-item
                                            @click="updateCalendarType('month')"
                                        >
                                            <v-list-item-title
                                                >Month</v-list-item-title
                                            >
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-toolbar>
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col cols="12">
                            <v-calendar
                                ref="calendar"
                                :events="data"
                                :event-color="getEventColor"
                                @change="change"
                                :type="filter.type"
                                event-more
                                event-overlap-mode="column"
                                @click:date="viewDay"
                                v-model="filter.focus"
                                @click:event="showEvent"
                            />

                            <v-menu
                                v-model="selectedOpen"
                                :close-on-content-click="false"
                                :activator="selectedElement"
                                offset-x
                            >
                                <v-card
                                    :dark="$vuetify.theme.dark"
                                    min-width="350px"
                                    flat
                                >
                                    <v-toolbar
                                        :color="selectedEvent.color"
                                        dark
                                        flat
                                    >
                                        <v-icon class="mr-2"
                                            >mdi-phone-settings</v-icon
                                        >
                                        <v-toolbar-title>{{
                                            selectedEvent.name
                                        }}</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            @click="selectedOpen = false"
                                            icon
                                        >
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-card-text>
                                        <p class="font-weight-bold">
                                            {{ selectedEvent.description }}
                                        </p>
                                        <p>
                                            Losing Carrier:
                                            {{ selectedEvent.losing_carrier
                                            }}<br />
                                            Status:
                                            {{ selectedEvent.current_status
                                            }}<br />
                                            Last Updated:
                                            <LocalDateTime
                                                :value="
                                                    selectedEvent.last_updated
                                                "
                                                show-time
                                                hide-tooltip
                                            />
                                        </p>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn
                                            text
                                            color="primary"
                                            :to="
                                                '/admin/porting/carrier/' +
                                                selectedEvent.id
                                            "
                                        >
                                            View Port
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import LocalDateTime from '../../../components/pieces/LocalDateTime';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'PortingCalendar',
    mixins: [apiMixin],
    data: () => ({
        loading: false,
        data: [],
        selectedEvent: {},
        selectedElement: null,
        selectedOpen: false,
        filter: {
            type: 'month',
            focus: '',
        },
    }),
    components: {
        LocalDateTime,
    },
    filters: {
        localdate: function (value) {
            if (!value) return '';
            value = value.toString();
            return new Date(value + 'Z');
        },
    },
    methods: {
        change(data) {
            this.updateCalendar(data.start.date, data.end.date);
            //this.update();
            //this.updateCalendar(data.start, data.end).then(this.update()).catch(console.log('error'));
        },
        getEventColor(event) {
            return event.color;
        },
        async updateCalendar(from, to) {
            this.loading = true;
            const response = await this.Api.send('post',
                'admin/porting/list/calendar',
                { from: from, to: to }
            );
            if (response.success) {
                const ports = [];
                if (response.data.data != null) {
                    response.data.data.forEach(function (item) {
                        item.name =
                            'PAF ' + item.id + ' - ' + item.customer_name;
                        item.start = new Date(item.port_date.toString() + 'Z');
                        item.end = new Date(
                            item.port_date.toString() + 'Z'
                        ).setHours(item.start.getHours() + 1);
                        item.color =
                            item.current_status === 'Completed'
                                ? 'green'
                                : 'blue';
                        item.timed = true;
                        ports.push(item);
                        /*ports.push({
                            name: 'PAF ' + item.id + ' - ' + item.customer_name,
                            start: new Date(item.port_date.toString() + 'Z'),
                            color: item.current_status === 'Completed' ? 'green' : 'blue',
                            timed: true,
                            id: item.id,
                            description: item.description
                        });*/
                    });
                }
                this.updateData(ports);
            } else {
                this.updateFailed(response.error, response.errorDetail);
            }
            this.loading = false;
        },
        contains(needle, haystack) {
            return haystack.toString().includes(needle);
        },
        setToday() {
            this.filter.focus = '';
        },
        prev() {
            this.$refs.calendar.prev();
        },
        next() {
            this.$refs.calendar.next();
        },
        showEvent({ nativeEvent, event }) {
            const open = () => {
                this.selectedEvent = event;
                this.selectedElement = nativeEvent.target;
                setTimeout(() => {
                    this.selectedOpen = true;
                }, 10);
            };

            if (this.selectedOpen) {
                this.selectedOpen = false;
                setTimeout(open, 10);
            } else {
                open();
            }

            nativeEvent.stopPropagation();
        },
        updateData(data) {
            this.data = data;
        },
        updateCalendarType(value) {
            if (value !== this.$route.query.type) {
                this.filter.type = value;
                this.updateRouteWithFilters();
            }
        },
        viewDay({ date }) {
            if (
                this.$route.query.type !== 'day' &&
                this.$route.query.date !== date
            ) {
                this.$router.push({
                    name: 'PortingCalendar',
                    query: { type: 'day', date: date },
                });
            }
        },
        updateRouteWithFilters() {
            if (
                this.$route.query.type !== this.filter.type ||
                this.$route.query.date !== this.filter.focus
            ) {
                let query = {};
                if (this.filter.type && this.filter.type !== 'month') {
                    query.type = this.filter.type;
                }
                if (this.filter.focus) {
                    query.date = this.filter.focus;
                }
                this.$router.push({ name: 'PortingCalendar', query: query });
            }
        },
        updateFailed() {
            this.data = [];
        },
    },
    watch: {
        $route(to) {
            if (to.query.type) {
                this.filter.type = to.query.type;
            } else {
                this.filter.type = 'month';
            }
            if (to.query.date) {
                this.filter.focus = to.query.date;
            }
        },
        'filter.focus'() {
            this.updateRouteWithFilters();
        },
    },
    created() {
        this.filter.type = this.$route.query.type
            ? this.$route.query.type
            : 'month';
        this.filter.focus = this.$route.query.date
            ? this.$route.query.date
            : '';
    },
};
</script>
