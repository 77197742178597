<template>
    <div>Test - Content to go here eventually</div>
</template>

<script>
export default {
    name: 'CommissionAdminSummary',
};
</script>

<style scoped></style>
