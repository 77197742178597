<template>
    <div>
        <v-card v-if="showFilters" class="pa-2 mb-2">
            <v-row>
                <v-col cols="12" sm="3">
                    <v-text-field
                        hide-details
                        outlined
                        clearable
                        dense
                        label="Name"
                        :disabled="isLoading"
                        v-model="localFilter.name"
                        @keydown.enter="updateLocalFilter"
                    />
                </v-col>
              <v-col cols="12" sm="3">
                <v-select
                    hide-details
                    outlined
                    dense
                    label="Account Type"
                    v-model="localFilter.accountType"
                    :disabled="isLoading"
                    :items="accountTypeOptions"
                    item-text="option"
                    item-value="value"
                />
              </v-col>
                <v-col cols="12" sm="3">
                    <v-select
                        hide-details
                        outlined
                        dense
                        label="Status"
                        v-model="localFilter.status"
                        :disabled="isLoading"
                        :items="statusOptions"
                        item-text="option"
                        item-value="value"
                    />
                </v-col>
                <v-col cols="12" :sm="addButton?2:3">
                    <v-btn
                            outlined
                            block
                            height="40"
                            :loading="isLoading"
                            @click="updateLocalFilter"
                    >Search</v-btn>
                </v-col>
              <v-col cols="12" sm="1" v-if="addButton">
                <v-btn
                    block
                    height="40"
                    :loading="isLoading"
                    @click="$emit('addButtonTriggered');"
                    color="primary"
                ><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>
        </v-card>
        <PaginationCard
            :page="filter.page ? filter.page : 1"
            :data="data"
            :limit="filter.limit ? filter.limit : 10"
            :loading="loading || isLoading"
            v-on:updateLimit="updateLimit"
            v-on:updatePage="updatePage"
            :no-skeleton="!firstLoad"
        >
            <v-simple-table>
                <thead>
                    <tr>
                        <th>Customer</th>
                        <th>Account type</th>
                        <th>Company</th>
                        <th>Balance</th>
                        <th v-if="$vuetify.breakpoint.smAndUp">Contact</th>
                        <th v-if="$vuetify.breakpoint.smAndUp">Address</th>
                        <th class="text-center">Status</th>
                        <th v-if="debug"></th>
                    </tr>
                </thead>
                <v-slide-x-transition tag="tbody" hide-on-leave group>
                    <tr v-if="data.data && data.data.length === 0" key="nodata">
                        <td :colspan="7" class="text-center">
                            No customers found
                        </td>
                    </tr>
                    <tr
                        v-for="item in data.data"
                        :key="item.i_customer"
                        v-bind:class="{
                            'red darken-4':
                                $vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'red lighten-4':
                                !$vuetify.theme.dark &&
                                item.bill_status !== 'C' &&
                                (item.blocked === 'Y' ||
                                    (item.bill_status === 'S' &&
                                        item.bill_suspension_delayed !== 1) ||
                                    item.balance > item.credit_limit),
                            'grey darken-3':
                                $vuetify.theme.dark && item.bill_status === 'C',
                            'grey lighten-3':
                                !$vuetify.theme.dark && item.bill_status === 'C',
                        }"
                    >
                        <td>
                          <router-link
                            tag="td"
                            :to="{
                                name: 'CustomerSummary',
                                params: { customerId: item.i_customer },
                            }"
                            style="cursor: pointer"
                            v-if="item.customer_class_label == 'BYO Wholesale'"
                        >
                            <a class="font-weight-medium">{{ item.name }}</a>
                        </router-link>

                          <router-link
                              tag="td"
                              :to="{
                                name: 'AdminWholesaleHome',
                                params: { customerId: item.i_customer },
                            }"
                              style="cursor: pointer"
                              v-else
                          >
                            <a class="font-weight-medium">{{ item.name }}</a>
                          </router-link>


                        </td>
                        <td>{{ item.customer_class_label}}</td>
                        <td>{{ item.companyname }}</td>
                        <td>
                          <Currency
                              :value="
                    item.i_balance_control_type == '1'
                        ? item.balance
                        : -item.balance
                "
                          />
                        </td>
                        <td v-if="$vuetify.breakpoint.smAndUp">
                            <span v-if="item.salutation"
                                >{{ item.salutation }}
                            </span>
                            <span v-if="item.firstname"
                                >{{ item.firstname }}
                            </span>
                            <span v-if="item.lastname">{{
                                item.lastname
                            }}</span>
                            <span
                                v-if="
                                    !item.salutation &&
                                    !item.firstname &&
                                    !item.lastname
                                "
                                >-</span
                            >
                        </td>
                        <td v-if="!small && item.baddr1">
                            <span v-if="item.baddr1">{{ item.baddr1 }}, </span>
                            <span v-if="item.address_line_2"
                                >{{ item.address_line_2 }},
                            </span>
                            <span v-if="item.city">{{ item.city }} </span>
                            <AddressState :value="item.state" />
                            <span v-if="item.zip"> {{ item.zip }}</span>
                        </td>
                        <td v-if="!small && !item.baddr1">None</td>
                        <td class="text-center">
                            <CustomerStatus :data="item" />
                        </td>
                      <td v-if="debug">
                        <ButtonWithTooltip
                          text="Env Port"
                          tooltip="Use this to build missing records for Wholesale Managed User records that are created on a different environment."
                          @click="sendEnvPort(item.i_customer)"
                          ></ButtonWithTooltip>
                      </td>
                    </tr>
                </v-slide-x-transition>
            </v-simple-table>
        </PaginationCard>
      <SimpleDialog
        title="Results from Environmental Porting"
        okOnly
        yesText="Close"
        :maxWidth="400"
        v-model="portingDialog"
        >
        <p>This process to do its best to convert a reseller from another environment to the current environment.</p>
        <p>EG If a Wholesale Managed User is created on staging certain records will be missing on a localhost version.
        This process will update the local record.</p>
        <p><strong>Results:</strong></p>
        <ul v-if="portMsg.length > 0">
          <li v-for="(item, index) in portMsg"
              :key="index">{{item}}</li>
        </ul>
        <p v-if="portMsg.length == 0">
          Nothing processed. This records is up to date.
        </p>

      </SimpleDialog>
    </div>
</template>

<script>
import PaginationCard from '@/components/cards/templates/PaginationCard';
import AddressState from '../../pieces/Address/AddressState';
import CustomerStatus from '../../pieces/Customer/CustomerStatus';
import refreshListenerMixin from '../../../mixins/refreshListenerMixin';
import apiMixin from '../../../mixins/apiMixin';
import paginationComponentMixin from '../../../mixins/paginationComponentMixin';
import Currency from "../../pieces/Currency";
import SimpleDialog from "../../dialogs/templates/SimpleDialog.vue";
import ButtonWithTooltip from "../../pieces/ButtonWithTooltip.vue";
export default {
    name: 'WholesalersListCard',
    mixins: [apiMixin, paginationComponentMixin, refreshListenerMixin],
    data: () => ({
        localFilter: {
            name: null,
            status: null,
            accountType: 'wholesale-managed',
        },
        statusOptions: [
            { option: 'Active (all)', value: null },
            { option: 'Active (not suspended)', value: 'O' },
            { option: 'Active (suspended)', value: 'S' },
            { option: 'Terminated', value: 'C' },
        ],
        accountTypeOptions: [
           // { option: 'Any', value: null},
            { option: 'Managed', value: 'wholesale-managed' },
            { option: 'Byo', value: 'wholesale-byo' },
        ],
        portMsg:[],
        portingDialog:false,

    }),
    components: {
      ButtonWithTooltip,
      SimpleDialog,
        CustomerStatus,
        AddressState,
        PaginationCard,
        Currency,
    },
    props: {
        repId: {
            type: Number,
            default: null,
        },
        small: {
            type: String,
            default: null,
        },
        addButton: {
          type: Boolean,
          default: false,
        },
    },
  computed:{
    debug() {
      return process.env.VUE_APP_DEBUG;
    },
  },
    methods: {
        async update() {
            this.isLoading = true;
            this.getLocalFilters();
            let params = { limit: this.filter.limit, page: this.filter.page };
            if (this.filter.status && this.filter.status !== '')
                params.status = this.filter.status;
            if (this.filter.accountType && this.filter.accountType !== '') {
              params.accountType = this.filter.accountType;

              if(params.accountType == 'wholesale-managed'){
                params.i_customer_type = 2;
              }else if(params.accountType == 'wholesale-byo'){
                params.i_customer_type = 1;
              }

            }
            if (this.filter.name && this.filter.name !== '')
                params.name = this.filter.name;
            if (this.repId > 0) {
                params.i_rep = this.repId;
            } else {
                if (this.getRepNames) params.get_rep_names = 1;
            }


            let queryString = Object.keys(params).map((key) => {
              return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
            }).join('&');

            //const response = await this.Api.send('post','customers/list', params);
          const response = await this.Api.send('get','customers?'+queryString);
            this.isLoading = false;
            if (response.success) {
                this.data = response.data;
                this.firstLoad = false;
            } else {
                this.data = [];
            }
        },

        async sendEnvPort(iCustomer) {
          this.isLoading = true;
          const response = await this.Api.send('put','wholesalers/'+iCustomer+'/environment-port');
          this.isLoading = false;
          if (response.success) {
            this.portMsg = response.data;
            this.portingDialog = true;
          } else {
            this.portMsg = [];
          }
        }
    },
};
</script>