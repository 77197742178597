<template>
    <v-card height="100%" class="flexcard" :busy="loading" :loading="loading">
        <v-card-text class="grow">
            <p class="overline">Representative Portal Login</p>

            <p class="title text--primary">{{ data.login }}</p>

            <p v-if="data.tax_id">Created: {{ data.tax_id }}</p>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
                <template #activator="{ on }">
                    <v-btn text v-on="on">
                        <v-icon left>mdi-refresh</v-icon> Change
                    </v-btn>
                </template>
                <span>Update/change login credentials</span>
            </v-tooltip>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: 'PartnerLoginCard',
    props: ['data', 'loading'],
};
</script>
