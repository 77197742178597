<template>
    <v-row dense>
        <v-col cols="12">
          <CustomerListCardV2 v-if="partnerData && partnerData.i_rep" :repId="partnerData.i_rep" :showRepNames="true"  add-button @addButtonTriggered="showNewCustomerAccountType = true"/>
        </v-col>
      <FloatingButton v-if="partnerData" tooltip="New Customer" icon="mdi-account-plus" @click="showNewCustomerAccountType=true" :loading="isBusy" />
      <NewCustomerAccountTypeDialog type="partner-customer" v-if="partnerData && partnerData.i_rep" :rep-id="partnerData ? partnerData.i_rep : null" v-model="showNewCustomerAccountType" @type-selected="function(classData){customerClassData=classData; showNewCustomer=true;}" />
      <NewCustomerDialog type="partner-customer" v-if="partnerData" v-model="showNewCustomer" :rep-id="partnerData.i_rep" :customer-class-data="customerClassData" @customer="goToCustomer" />
    </v-row>
</template>

<script>
import CustomerListCardV2 from "../../components/cards/Customer/CustomersListCardV2";
import filterMixin from '../../mixins/filterMixin';
import partnerDataMixin from '../../mixins/partnerDataMixin';
import FloatingButton from '../../components/pieces/FloatingButton';
import NewCustomerDialog from '../../components/dialogs/Customer/NewCustomerDialog';
import NewCustomerAccountTypeDialog from "../../components/dialogs/Customer/NewCustomerAccountTypeDialog";
export default {
    name: 'PartnerCustomers',
    components: {
        NewCustomerAccountTypeDialog,
        NewCustomerDialog,
        FloatingButton,
        CustomerListCardV2
    },
    methods: {
        goToCustomer(id) {
          this.showNewCustomer = false;
          this.showNewCustomerAccountType = false;
          this.$router.push({ name: 'CustomerSummary', params: { customerId: id }});
        }
    },
    mixins: [filterMixin, partnerDataMixin],
    data: () => ({
        showNewCustomer: false,
        showNewCustomerAccountType:false,
        customerClassData:{}
    }),
    beforeRouteLeave(to, from, next){
        return (this.showNewCustomer) ? next(false) : next();
    },
    computed:{
      isBusy( ){
        return !this.partnerLoading && !(this.partnerData && this.partnerData.i_rep)
      }
    }
};
</script>
