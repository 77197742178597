<template>
    <v-container fluid>
        <PartnerListCard/>
    </v-container>
</template>

<script>
import PartnerListCard from "../../components/cards/Partner/PartnerListCard";
export default {
    name: 'PartnerList',
    mixins: [],
    data: () => ({
    }),
    components: {
      PartnerListCard,

    },
    computed: {
      isBusy: function( ){
        //return this.loading || this.busy;
        return this.busy;
      },
    },
    methods: {

    },
};
</script>
