<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <BasicCard :loading="loading" no-skeleton>
                    <v-row>
                        <v-col cols="6">
                            <DatePicker
                                hint="Start Date"
                                v-model="startDate"
                                persistent-hint
                                required
                            />
                        </v-col>
                        <v-col cols="6">
                            <DatePicker
                                hint="End Date"
                                v-model="endDate"
                                persistent-hint
                                required
                            />
                        </v-col>
                    </v-row>
                    <p class="text--primary pt-4">
                        Report from
                        <span v-if="startDateTime">{{ startDateTime }} UTC</span
                        ><span v-else>unknown</span> to
                        <span v-if="endDateTime">{{ endDateTime }} UTC</span
                        ><span v-else>unknown</span>.
                    </p>
                    <template v-slot:actions>
                        <v-btn
                            text
                            :disabled="!startDateTime || !endDateTime"
                            @click="generate"
                            >Generate Report</v-btn
                        >
                    </template>
                </BasicCard>
            </v-col>
            <v-col cols="12">
                <BasicCard
                    v-if="step"
                    title="Generating Commissions Report..."
                    class="text--primary"
                    no-skeleton
                >
                    <p v-if="step >= 1">
                        <b>Step 1</b> Getting partner list (page
                        {{ currentPage || 1
                        }}<span v-if="pageCount"> of {{ pageCount }}</span
                        >)...<span v-if="step > 1" class="green--text">
                            OK</span
                        >
                    </p>
                    <p v-if="step >= 2">
                        <b>Step 2</b> Getting commission totals for the
                        specified period...<span
                            v-if="step > 2"
                            class="green--text"
                        >
                            OK</span
                        >
                    </p>
                    <v-progress-linear
                        rounded
                        height="25"
                        class="my-4"
                        :value="taskProgress"
                        v-if="step && step > 0 && step < 3"
                    >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                    <template v-slot:actions v-if="step === 3">
                        <v-btn text @click="download">Download CSV</v-btn>
                    </template>
                </BasicCard>
            </v-col>
            <v-col cols="12">
                <BasicCard
                    v-if="data.length > 0"
                    title="Commissions Report Data"
                    no-skeleton
                >
                    <v-simple-table dense>
                        <thead class="text-left">
                            <tr>
                                <th>Partner ID</th>
                                <th>DealerCode</th>
                                <th>Partner Name</th>
                                <th>Current Balance</th>
                                <th>Commission Total</th>
                                <th>Payment Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in data" :key="item.i_rep">
                                <td>{{ item.i_rep }}</td>
                                <td>{{ item.refnum }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.balance | currency }}</td>
                                <td>{{ item.total | currency }}</td>
                                <td>
                                    <b>{{ item.payment_amount | currency }}</b>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </BasicCard>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import BasicCard from '../../../components/cards/templates/BasicCard';
import DatePicker from '../../../components/pieces/Forms/DatePicker';
import apiMixin from '../../../mixins/apiMixin';
export default {
    name: 'CommissionDownloadReport',
    mixins: [apiMixin],
    components: { DatePicker, BasicCard },
    data: () => ({
        csv: [],
        data: [],
        loading: false,
        step: null,
        partnersCompleted: null,
        currentPage: null,
        pagesCompleted: null,
        pageCount: null,
        representativeCount: null,
        startDate: null,
        endDate: null,
    }),
    methods: {
        download: function () {
            let csv = this.$papa.unparse(this.data);
            if (csv) this.$papa.download(csv, 'commissions_report.csv');
        },
        generate: async function () {
            this.loading = true;
            await this.getPartners();
            await this.getPartnerComms();
            this.step = 3;
            this.loading = false;
        },
        getPartnerComms: async function () {
            this.step = 2;
            for (var i = 0; i < this.data.length; i++) {
                var total = await this.getPartnerCommsTotal(this.data[i].i_rep);
                this.data[i].total = total;
                var balance = this.data[i].balance;
                this.data[i].payment_amount =
                    balance > total
                        ? Math.round(total * 1e2) / 1e2
                        : Math.round(balance * 1e2) / 1e2;
                this.partnersCompleted++;
            }
        },
        getPartnerCommsTotal: async function (partnerId) {
            let params = { from: this.startDateTime, to: this.endDateTime };
            const response = await this.Api.send('post',
                'partners/' + partnerId + '/commission/totals',
                params
            );
            if (response.success) {
                return response.data.total_commission;
            }
        },
        getPartners: async function () {
            this.step = 1;
            this.data = [];
            this.pageCount = null;
            this.pagesCompleted = null;
            await this.getPartnerPage(1);
            for (var i = 2; i <= this.pageCount; i++) {
                await this.getPartnerPage(i);
            }
        },
        getPartnerPage: async function (page) {
            let params = { limit: 100, page: page, hidden: 'n' };
            this.currentPage = page;
            const response = await this.Api.send('post','partners/list', params);
            if (response.success && response.data.data) {
                this.pageCount = response.data.pages;
                for (var i = 1; i < response.data.data.length; i++) {
                    this.data.push({
                        i_rep: response.data.data[i].i_rep,
                        name: response.data.data[i].name,
                        refnum: response.data.data[i].refnum,
                        balance: response.data.data[i].balance,
                    });
                }
                this.pagesCompleted = page;
            } else {
                console.log([response.error, response.errorDetail]);
            }
        },
    },
    filters: {
        currency: function (value) {
            if (value) {
                return (
                    '$' +
                    value
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                );
            } else if (value === undefined) {
                return '-';
            }
            return '$0.00';
        },
    },
    computed: {
        taskProgress: function () {
            switch (this.step) {
                case 1:
                    if (this.pageCount && this.pagesCompleted) {
                        return (this.pagesCompleted / this.pageCount) * 100;
                    } else {
                        return 0;
                    }
                case 2:
                    if (this.data.length && this.partnersCompleted) {
                        return (
                            (this.partnersCompleted / this.data.length) * 100
                        );
                    } else {
                        return 0;
                    }
                default:
                    return null;
            }
        },
        startDateTime: function () {
            if (!this.startDate) return null;
            return new Date(this.startDate + ' 00:00:00')
                .toISOString()
                .replace(/T/, ' ')
                .replace(/\..+/, '');
        },
        endDateTime: function () {
            if (!this.endDate) return null;
            return new Date(this.endDate + ' 00:00:00')
                .toISOString()
                .replace(/T/, ' ')
                .replace(/\..+/, '');
        },
    },
};
</script>
